import { Card, Heading } from 'ui'
import styles from './InvoicingChange.module.scss'

export const InvoicingChange = () => {
  return (
    <Card info light>
      <Heading>Votre espace client pro : simplifiez votre gestion</Heading>
      <div className={styles.invoicing}>
        Nous facturons automatiquement les commandes réceptionnées par les
        clients au cours du mois. Si une commande n'est pas confirmée comme
        réceptionnée par le client sous 22 jours, elle sera automatiquement
        considérée comme telle.
        <br />
        Retrouvez facilement l'historique complet de vos commandes dans
        l'onglet&nbsp;
        <b>Historique</b> depuis votre page d'accueil.
      </div>
    </Card>
  )
}
