import React, { Component } from 'react'
import { Navigate } from 'react-router'
import styles from './ListContainer.module.scss'
import {
  Button,
  FileUpload,
  Grid,
  IconClose,
  IconExport,
  IconFilter,
  LayoutFixed,
  MenuSecondary,
  Removable,
  ShippingCard,
} from 'ui'
import { FormContactModal } from '../../components'
import { ListActions, SupplierNavigation } from 'features/suppliers/components'
import { NotificationManager } from 'services/notifications'
import { downloadPDFContent } from 'utils/data'
import { getRouteParam, setRouteParam } from 'utils/route'
import {
  addTrackingShipping,
  acceptAllShippings,
  sendAllShipping,
  acceptShipping,
  sendShipping,
  getShippings,
  printTrackingShipping,
  ShippingStates,
  importChronopostFile,
} from 'services/shippings'
import { initThread } from 'services/messages'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'
import { getMenuLinks } from './@commons'
import {
  FormFilterModal,
  FormExportModal,
} from 'features/suppliers/shippings/components'
import { MIME_CSV } from 'utils/file'

const CONTACT_SENT = 'Le message a été envoyé'
const TRACKING_ADDED = 'Le numéro de suivi a été ajouté avec succès'
const TRACKING_GENERATED = "L'étiquette a été générée avec succès"
const EMPTY_PRINT_DURATION = 360

const INIT_SEARCH_VALUE = { value: '', dateMin: null, dateMax: null }

class ShippingsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      empty: false,
      error: '',
      exportStatuses: [
        ShippingStates.accepted.value,
        ShippingStates.sent.value,
      ],
      exportOpened: false,
      filtersOpened: false,
      loading: false,
      exceptions: null,
      pagination: null,
      query: null,
      result: { shippings: [], stats: {}, supplier: null, shippers: [] },
      search: INIT_SEARCH_VALUE,
      contacting: null,
    }
  }

  supplierKey = () => this.props.params.uniqueKey
  status = () => this.props.params.status
  page = () => parseInt(getRouteParam(this.props.location, 'page')) || 1
  query = () => getRouteParam(this.props.location, 'q') || ''
  dateMin = () => getRouteParam(this.props.location, 'min') || ''
  dateMax = () => getRouteParam(this.props.location, 'max') || ''
  search() {
    return {
      query: this.query(),
      dateMin: this.dateMin(),
      dateMax: this.dateMax(),
    }
  }
  hasChronopost = () => {
    const { result } = this.state
    const hasChronopostShipper =
      result.shippers.find(s => s.value == 'Chronopost') !== undefined

    return hasChronopostShipper
  }

  loadData = () => {
    return getShippings({
      page: this.page(),
      filters: this.search(),
      status: this.status(),
      uniqueKey: this.supplierKey(),
    })
  }

  onDataLoaded = data => {
    if (data.exceptions?.length > 0) {
      window.location.href = '/login'
      return
    }
    this.setState({ empty: false, ...data })
  }

  onError = error => this.setState({ empty: false, error, loading: false })

  onAcceptAll = () => {
    return waitForApi(acceptAllShippings(this.supplierKey(), this.search()))
      .then(this.onAcceptAllSuccess)
      .catch(this.onError)
  }

  onAcceptAllSuccess = data => {
    this.goToFirstPage()

    const countIds = data.result.updatedIds.length
    let message = `${countIds} commandes ont été acceptées`
    if (countIds === 1) {
      message = `1 commande a été acceptée`
    }
    NotificationManager.addSuccess(message)
  }

  onSendAll = () => {
    return waitForApi(sendAllShipping(this.supplierKey(), this.search()))
      .then(this.onSendAllSuccess)
      .catch(this.onError)
  }

  onSendAllSuccess = data => {
    this.goToFirstPage()

    const countIds = data.result.updatedIds.length
    let message = `${countIds} commandes ont été marquées Expédié`
    if (countIds === 1) {
      message = `1 commande a été marqué Expédié`
    }
    NotificationManager.addSuccess(message)
  }

  onTrackingImport = file => {
    this.setState({ loading: true })

    return waitForApi(
      importChronopostFile(this.props.params.uniqueKey, file.content)
    )
      .then(result => {
        if (result.exceptions.length > 0) {
          return this.onError(result.exceptions[0])
        }
        this.onImportChronopostFileSuccess(result)
      })
      .catch(this.onError)
  }

  onImportChronopostFileSuccess = ({ result: { nbUpdated } }) => {
    this.goToFirstPage()

    let message = ''
    if (nbUpdated === 0) {
      message = `Aucun numéro de suivi n'a été mis à jour`
      NotificationManager.addWarning(message)
      return
    }

    message = `${nbUpdated} numéros de suivi ont été mis à jour`
    if (nbUpdated === 1) {
      message = `1 numéro de suivi a été mis à jour`
    }
    NotificationManager.addSuccess(message)
  }

  onFilterCancel = () => {
    this.setState({ filtersOpened: false, search: INIT_SEARCH_VALUE })
  }

  onFilterValid = search => {
    this.setState({ filtersOpened: false })
    this.onFilter(search)
  }

  onFilter = filter => {
    let location = setRouteParam(this.props.location, 'q', filter.query)
    location = setRouteParam(location, 'min', filter.dateMin)
    location = setRouteParam(location, 'max', filter.dateMax)
    location = setRouteParam(location, 'page', 1)
    this.props.navigate(location)
  }

  onShippingContact = contacting => {
    this.setState({ contacting })
  }

  onContactValid = (category, message, internal) => {
    const shippingId = this.state.contacting
    this.setState({ loading: true })

    return waitForApi(initThread({ shippingId, category, message, internal }))
      .then(({ result: { threadRootKey } }) => {
        this.setState({
          loading: false,
          contacting: null,
        })

        const shippings = [...this.state.result.shippings]
        var updatedIndex = shippings.findIndex(s => s.id == shippingId)
        shippings[updatedIndex] = { ...shippings[updatedIndex], threadRootKey }

        this.setState({
          loading: false,
          result: {
            ...this.state.result,
            shippings,
          },
        })
        NotificationManager.addSuccess(CONTACT_SENT)
      })
      .catch(this.onError)
  }

  onShippingAccept = id => {
    // Set Happy Path state
    const stats = { ...this.state.result.stats }
    const shippings = [...this.state.result.shippings]
    stats.received--
    stats.accepted++
    shippings.forEach(s => s.id === id && (s.removed = true))

    const result = {
      ...this.state.result,
      stats,
      shippings,
    }
    const empty = stats.received === 0
    this.setState({ result })
    setTimeout(() => this.setState({ empty }), EMPTY_PRINT_DURATION)

    // Call API for change (due to happy path, no callback if everything's correct)
    return acceptShipping(this.supplierKey(), id).catch(this.onError)
  }

  onShippingSent = id => {
    // Set Happy Path state
    const stats = { ...this.state.result.stats }
    const shippings = [...this.state.result.shippings]
    stats.accepted--
    stats.sent++
    shippings.forEach(s => s.id === id && (s.removed = true))
    const result = {
      ...this.state.result,
      stats,
      shippings,
    }
    const empty = stats.accepted === 0
    this.setState({ result })
    setTimeout(() => this.setState({ empty }), EMPTY_PRINT_DURATION)

    // Call API for change (due to happy path, no callback if everything's correct)
    return sendShipping(this.supplierKey(), id).catch(this.onError)
  }

  onShippingTrackingAdd = (id, trackingRef, shipperId) => {
    this.setState({ loading: true })

    return waitForApi(
      addTrackingShipping(this.supplierKey(), id, trackingRef, shipperId)
    )
      .then(this.trackingShippingException)
      .then(({ result: { shipping } }) => {
        const shippings = [...this.state.result.shippings]
        var updatedIndex = shippings.findIndex(s => s.id == id)
        shippings[updatedIndex] = shipping

        this.setState({
          loading: false,
          result: {
            ...this.state.result,
            stats: { ...this.state.result.stats },
            shippings,
          },
        })
        NotificationManager.addSuccess(TRACKING_ADDED)
      })
      .catch(this.onError)
  }

  trackingShippingException = data => {
    if (data.exceptions?.length > 0) {
      data.exceptions.map(e => NotificationManager.addError(e))
      return Promise.reject()
    }
    return Promise.resolve(data)
  }

  onShippingTrackingPrint = (id, value) => {
    this.setState({ loading: true })

    return waitForApi(printTrackingShipping(this.supplierKey(), id, value))
      .then(this.trackingShippingException)
      .then(res => {
        downloadPDFContent(`colissimo-${id}`, res.result.label)

        let shippings = [...this.state.result.shippings]
        const stats = { ...this.state.result.stats }
        shippings.forEach(
          s => s.id === id && (s.trackingRef = res.result.trackingRef)
        )
        const result = {
          ...this.state.result,
          stats,
          shippings,
        }
        this.setState({
          loading: false,
          result,
        })
        NotificationManager.addSuccess(TRACKING_GENERATED)
      })
      .catch(this.onError)
  }

  goToFirstPage() {
    let location = setRouteParam(this.props.location, 'q', '')
    location = setRouteParam(location, 'min', '')
    location = setRouteParam(location, 'max', '')
    location = setRouteParam(location, 'page', 1)
    location = setRouteParam(location, 'r', Date.now()) // force refresh

    this.props.navigate(location)
  }

  addAcceptAllAction(actions) {
    const status = this.status()
    if (status === ShippingStates.received.value) {
      actions.push(
        <Button primary onClick={this.onAcceptAll}>
          Tout accepter
        </Button>
      )
    }
  }

  addFilterActions(actions) {
    return actions.push(
      <div>
        <Button
          light
          hideLabelOnMobile
          onClick={() => this.setState({ filtersOpened: true })}
          icon={<IconFilter />}
        />
        {this.query() && (
          <span className={styles.filter}>
            <Button
              small
              onClick={() => this.onFilter({ ...this.search(), query: '' })}
              icon={<IconClose />}
            >
              {this.query()}
            </Button>
          </span>
        )}
        {this.dateMin() && (
          <span className={styles.filter}>
            <Button
              small
              onClick={() => this.onFilter({ ...this.search(), dateMin: '' })}
              icon={<IconClose />}
            >
              Depuis le {this.dateMin()}
            </Button>
          </span>
        )}
        {this.dateMax() && (
          <span className={styles.filter}>
            <Button
              small
              onClick={() => this.onFilter({ ...this.search(), dateMax: '' })}
              icon={<IconClose />}
            >
              Jusqu'au {this.dateMax()}
            </Button>
          </span>
        )}
      </div>
    )
  }

  addExportAction(actions) {
    if (this.state.exportStatuses.includes(this.status())) {
      actions.push(
        <Button
          light
          hideLabelOnMobile
          onClick={() => this.setState({ exportOpened: true })}
          icon={<IconExport />}
        />
      )
    }
  }

  addChronopostAction(actions) {
    const status = this.status()
    if (status === ShippingStates.accepted.value && this.hasChronopost()) {
      actions.push(
        <FileUpload light accept={[MIME_CSV]} onChange={this.onTrackingImport}>
          Import Chronopost
        </FileUpload>
      )
    }
  }

  addSendAllAction(actions) {
    const {
      result: { shippings, supplier },
    } = this.state

    const status = this.status()
    const hasShippingWithTracking = shippings.reduce(
      (acc, sh) => acc || sh.trackingRef?.length > 0,
      false
    )
    const isDisabled = supplier?.trackingRequired && !hasShippingWithTracking

    if (status === ShippingStates.accepted.value) {
      actions.push(
        <Button primary disabled={isDisabled} onClick={this.onSendAll}>
          Tout marquer Expédié
        </Button>
      )
    }
  }

  getSupplierRedirection = (supplierKey, supplier) => {
    const prefix = `/suppliers/${supplierKey}`

    if (!supplier) return
    if (supplier.isAdmin) return

    if (supplier.showIntro) return `${prefix}/intro`
    if (supplier.showFormCompany) return `${prefix}/config/company`
    if (supplier.showFormExpedition) return `${prefix}/config/expedition`

    return
  }

  renderLeftActions() {
    const actions = []

    this.addExportAction(actions)
    this.addFilterActions(actions)

    return actions
  }

  renderRightActions() {
    const actions = []

    this.addAcceptAllAction(actions)
    this.addChronopostAction(actions)
    this.addSendAllAction(actions)

    return actions
  }

  renderModals() {
    const { contacting, filtersOpened, exportOpened } = this.state

    return (
      <>
        <FormContactModal
          opened={!!contacting}
          askCategory={true}
          askInternal={true}
          title={'Contacter le client ou un administrateur'}
          onCancel={() => this.setState({ contacting: null })}
          onSubmit={this.onContactValid}
        />
        <FormFilterModal
          opened={filtersOpened}
          onCancel={() => this.setState({ filtersOpened: false })}
          onSubmit={this.onFilterValid}
          search={this.search()}
        />
        <FormExportModal
          opened={exportOpened}
          supplierKey={this.supplierKey()}
          status={this.status()}
          onClose={() => this.setState({ exportOpened: false })}
          search={this.search()}
          hasChronopost={this.hasChronopost()}
        />
      </>
    )
  }

  render() {
    const supplierKey = this.supplierKey()
    const {
      empty,
      error,
      loading,
      result: { supplier, shippings, stats, shippers },
    } = this.state

    const redirect = this.getSupplierRedirection(supplierKey, supplier)
    if (redirect) return <Navigate to={redirect} />

    return (
      <LayoutFixed
        title="Commandes"
        actions={
          <ListActions
            left={this.renderLeftActions()}
            right={this.renderRightActions()}
          />
        }
        nav={
          <SupplierNavigation
            supplierKey={supplierKey}
            supplierName={supplier?.name}
            title={'Commandes'}
          />
        }
        menu={<MenuSecondary links={getMenuLinks(supplierKey, stats)} />}
        offscreen={this.renderModals()}
        loading={loading}
        empty={empty}
        error={error}
        loadData={this.loadData}
        onDataLoaded={this.onDataLoaded}
      >
        <Grid
          cols={1}
          items={shippings.map(s => (
            <Removable key={s.id} removed={s.removed}>
              <ShippingCard
                shipping={s}
                supplier={supplier}
                supplierKey={this.supplierKey()}
                shippers={shippers}
                onContact={this.onShippingContact}
                onAccept={this.onShippingAccept}
                onSent={this.onShippingSent}
                onTrackingAdd={this.onShippingTrackingAdd}
                onTrackingPrint={this.onShippingTrackingPrint}
              />
            </Removable>
          ))}
        />
      </LayoutFixed>
    )
  }
}

export const FeatureSuppliersShippingsList = withRouter(ShippingsList)
